// ** React Imports
import { useState, Fragment, useCallback, useEffect } from "react";

// ** Next Import
import { useNavigate } from "react-router-dom";

// ** MUI Imports
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

// import downArrowicons from "../../../../assets/images/downArrowicons.svg";
import ArrowDownIconSvg from "../../../../assets/images/ArrowDownIconSvg.svg";
// ** Context
import { useAuth } from "../../../../hooks/useAuth";
import { Typography } from "@mui/material";
import LogoutDialog from "../../../../components/dialog/logoutDialog";
import { MEDIA_URL } from "../../../../network/endpoints";
import Translations from "../../../../layouts/components/Translations";

// ** Styled Components
const BadgeContentSpan = styled("span")(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));
const UserNameMUITypo = styled(Typography)(({ theme }) => ({
  color: "#111827",
  fontFamily: "Cairo, sans-serif",
  fontSize: "16px",
  fontWeight: "bold",
  lineHeight: "25px",
}));

const MainBoxWrapper = styled(Box)`
gap: '10px',
padding: '3px 5px',
display: 'flex',
alignItems: 'center',
borderRadius: '200px',
border: '1px solid rgba(80, 80, 80, 0.20)',
cursor: 'pointer'
`;

const UserDropdown = (props) => {
  // ** Props
  const { settings } = props;

  // ** States
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  // ** Hooks
  const navigate = useNavigate();
  // const { logout } = useAuth()

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url) => {
    if (url) {
      setTimeout(() => {
        navigate(url);
      }, 1);
    }
    setAnchorEl(null);
  };
  const handleSettingClose = (url) => {
    if (url) {
      setTimeout(() => {
        navigate(url);
      }, 1);
    }
    setAnchorEl(null);
  };

  const handleLogoutDialog = () => {
    setOpen((prev) => !prev);
    setAnchorEl(null);
  };

  const styles = {
    py: 1,
    px: 4,
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "text.primary",
    textDecoration: "none",
    fontWight: "bold",
    "& svg": {
      fontSize: "1.375rem",
      color: "text.secondary",
    },
  };

  const MenuItemTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Cairo, sans-serif",
    fontWeight: "bold",
    lineHeight: "45px",
    fontStyle: "normal",
    textAlign: "center",
    color: "#707070",
  }));

  // const handleLogout = () => {
  //   // logout()
  //   localStorage.clear()
  //   handleDropdownClose()
  //   navigate('/login')
  // }

  const { user } = useAuth();
  // const navigationLinks = savedToken ? loggedInLinks : alwaysVisibleLinks

  const UserNameTypo = styled(Typography)`
    color: #303a4b;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    text-transform: capitalize;
  `;

  return (
    <>
      <Fragment>
        <Box
          sx={{
            gap: "10px",
            padding: "3px 5px",
            display: {
              xl: "flex",
              lg: "flex",
              md: "flex",
              sm: "none",
              xs: "none",
            },
            alignItems: "center",
            borderRadius: "200px",
            border: "1px solid #D9DFE8",
            background: "#F1F5F9",
            cursor: "pointer",
          }}
          onClick={handleDropdownOpen}
        >
          <Badge
            overlap="circular"
            sx={{ cursor: "pointer" }}
            badgeContent={<BadgeContentSpan />}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Avatar
              alt={user.first_name}
              sx={{ width: 35, height: 35 }}
              src={`${MEDIA_URL}${user.profile_picture}`}
            />
          </Badge>
          <UserNameMUITypo>{user.first_name}</UserNameMUITypo>
          <img src={ArrowDownIconSvg} alt="ArrowDownIconSvg"></img>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleDropdownClose()}
          sx={{
            "& .MuiMenu-paper": { width: 230, mt: 4 },
            display: {
              xl: "none",
              lg: "none",
              md: "none",
              sm: "none",
              xs: "none",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: direction === "ltr" ? "right" : "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: direction === "ltr" ? "right" : "left",
          }}
        >
          <MenuItem
            sx={{ p: 0 }}
            onClick={() => handleSettingClose("/settings/main/buyer")}
          >
            <Box sx={styles}>
              <Translations text={"My Profile"} />
            </Box>
          </MenuItem>
          <Divider sx={{ mt: 0, mb: 1 }} />
          {/* <MenuItem
            sx={{ p: 0 }}
            onClick={() => handleDropdownClose("/user-change-password")}
          >
            <Box sx={styles}>
              Change Password
            </Box>
          </MenuItem> */}
          <Divider sx={{ mt: 0, mb: 1 }} />
          <MenuItem sx={{ p: 0 }} onClick={() => handleLogoutDialog("")}>
            <Box sx={styles}>
              <Translations text={"Logout"} />
            </Box>
          </MenuItem>
        </Menu>
      </Fragment>
      <Fragment>
        <Badge
          overlap="circular"
          onClick={handleDropdownOpen}
          sx={{
            cursor: "pointer",
            display: {
              xl: "none",
              lg: "none",
              md: "none",
              sm: "flex",
              xs: "flex",
            },
          }}
          badgeContent={<BadgeContentSpan />}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Avatar
            alt={user.first_name}
            onClick={handleDropdownOpen}
            sx={{ width: 40, height: 40 }}
            src={`${MEDIA_URL}${user.profile_picture}`}
          />
        </Badge>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleDropdownClose()}
          sx={{
            "& .MuiMenu-paper": {
              width: 150,
              borderRadius: "15px",
              mt: 1,
              background: "#F1F5F9",
            },
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "block",
              xs: "block",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: direction === "ltr" ? "right" : "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: direction === "ltr" ? "right" : "left",
          }}
        >
          {/* <Box sx={{ pt: 2, pb: 3, px: 4 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Badge
                overlap="circular"
                badgeContent={<BadgeContentSpan />}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <Avatar
                  alt={user.first_name}
                  src={`${MEDIA_URL}${user.profile_picture}`}
                  sx={{ width: "2.5rem", height: "2.5rem" }}
                />
              </Badge>
              <Box
                sx={{
                  display: "flex",
                  ml: 3,
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <UserNameMUITypo>{user?.first_name || ""}</UserNameMUITypo>
              </Box>
            </Box>
          </Box> */}
          <MenuItem
            disableGutters
            sx={{ p: 0, width: "100%" }}
            onClick={() => handleDropdownClose("/settings/main/buyer")}
          >
            <Box display={"flex"} justifyContent={"center"} width={"100%"}>
              <MenuItemTypo>
                <Translations text={"My Profile"} />
              </MenuItemTypo>
            </Box>
          </MenuItem>
          <Divider sx={{ mt: 0, mb: 1 }} />
          {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose("/user-change-password")}>
            <Box sx={styles}>
              Change Password
            </Box>
          </MenuItem> */}
          <MenuItem sx={{ p: 0 }} onClick={() => handleLogoutDialog("")}>
            <Box display={"flex"} justifyContent={"center"} width={"100%"}>
              <MenuItemTypo>
                <Translations text={"Logout"} />
              </MenuItemTypo>
            </Box>
          </MenuItem>
        </Menu>
      </Fragment>
      <LogoutDialog open={open} toggle={handleLogoutDialog} />
    </>
  );
};

export default UserDropdown;
