// MUI IMPORTS
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";

// FILE IMAGE IMPORTS
import Logo_Thanawi from "../../assets/images/logo_Thanawi.svg";
import TwitterXIcon from "../../assets/images/Twitter-X-Icon.svg";
import LinkedInIcon from "../../assets/images/LinkedIn-Icon.svg";

import { useNavigate, Link as RLink } from "react-router-dom";

import { Link, animateScroll as scroll } from "react-scroll";

const INFORMATIONList = ["Home", "Buy", "Sell", "About Us", "KnowledgeHub"];

const navItem = [
  { id: 0, name: "Home", path: "home", tagName: Link },
  // { id: 1, name: "Buy", path: "sellAndBuy", tagName: Link },
  // { id: 2, name: "Sell", path: "sellAndBuy", tagName: Link },
  { id: 3, name: "About Us", path: "/about-us", tagName: RLink },
  {
    id: 4,
    name: "KnowledgeHub",
    path: "OpportunitySection",
    tagName: Link,
  },
];

console.log(
  "DEBUG INFORMATIONList",
  INFORMATIONList.map((item) => item)
);

const BoxWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "30vh",
  backgroundColor: "#EAECEE",
  opacity: 1,
  paddingInline: "1rem",
  paddingBlock: "1rem",
  display: "flex",
  [theme.breakpoints.up("sm")]: {
    paddingInline: "5rem",
    paddingBlock: "10rem",
  },
  [theme.breakpoints.down("md")]: {
    height: "100%",
  },
}));
const CopyrightTypo = styled(Typography)(({ theme }) => ({
  color: "#707070",
  opacity: 1,
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "25px",
  fontFamily: "Cairo, sans-serif",
  letterSpacing: "0px",
}));
const HeadingTypo = styled(Typography)(({ theme }) => ({
  color: "#007168",
  fontSize: "30px",
  letterSpacing: "0px",
  opacity: 1,
  fontStyle: "normal",
  fontWeight: "bold",
  lineHeight: "45px",
  fontFamily: "Cairo, sans-serif",
}));

const listTypo = {
  color: "#1C355E",
  fontSize: "20px",
  fontWeight: "bold",
  fontStyle: "normal",
  lineHeight: "25px",
  fontFamily: "Cairo, sans-serif",
  cursor: "pointer",
};
function Footer() {
  const navigate = useNavigate();

  const handleNavigateToContactUs = () => {
    navigate("/contact-us");
  };

  const handleNavigateToTerms = () => {
    navigate("/terms-condition");
  };

  const handleNavigate = (url) => {
    window.open(url);
  };

  return (
    <>
      <BoxWrapper>
        <Grid
          container
          justifyContent={"center"}
          alignContent={"center"}
          gap={5}
        >
          <Grid
            item
            xs={12}
            md={3}
            textAlign={"left"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-end"}
            gap={2}
          >
            <Box>
              <img
                src={Logo_Thanawi}
                alt="thanawi_Logo"
                style={{ width: "191px", opacity: 1 }}
              />
            </Box>
            <CopyrightTypo>
              Copyright @2023 Thanawi, All Rights Reserve
            </CopyrightTypo>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <HeadingTypo>INFORMATION</HeadingTypo>
            <List disablePadding>
              {navItem.map((item) => {
                return (
                  <>
                    <item.tagName
                      key={item.id}
                      to={item.path}
                      {...(item.tagName === Link
                        ? { activeClass: "active", spy: true, smooth: true }
                        : {})}
                      style={{
                        textDecoration: item.tagName === RLink ? "none" : null,
                        cursor: "pointer",
                      }}
                    >
                      <ListItem disablePadding>
                        <ListItemText
                          primary={item.name}
                          primaryTypographyProps={{
                            sx: { ...listTypo },
                          }}
                        />
                      </ListItem>
                    </item.tagName>
                  </>
                );
              })}
            </List>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <HeadingTypo>LEGAL</HeadingTypo>
            <List disablePadding>
              <ListItem disablePadding>
                <ListItemText
                  primary={"Terms & Conditions"}
                  primaryTypographyProps={{
                    sx: {
                      ...listTypo,
                    },
                  }}
                  onClick={handleNavigateToTerms}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <HeadingTypo>SUPPORT</HeadingTypo>
            <List disablePadding>
              <ListItem disablePadding>
                <ListItemText
                  primary={"Contact Us"}
                  primaryTypographyProps={{
                    sx: {
                      ...listTypo,
                    },
                  }}
                  onClick={handleNavigateToContactUs}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            xs={12}
            md={1}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"flex-end"}
            gap={2}
          >
            <Avatar
              alt="twitter"
              src={TwitterXIcon}
              variant="square"
              sx={{ cursor: "pointer" }}
              onClick={() => handleNavigate("https://twitter.com/thanawi_io")}
            />
            <Avatar
              alt="twitter"
              src={LinkedInIcon}
              variant="square"
              sx={{ cursor: "pointer" }}
              onClick={() =>
                handleNavigate("https://www.linkedin.com/company/thanawi/")
              }
            />
          </Grid>
        </Grid>
      </BoxWrapper>
    </>
  );
}
export default Footer;
