export const Routes = {
  HOME: "/",
  LOGIN: "/login",
  DASHBOARD: "/dashboard/main",
};

export const PublicRoutes = [
  "/",
  "/login",
  "/register",
  "/forgotPassword",
  "/otp-verify",
  "/nda",
  "/privacy-policy",
  "/terms-condition",
  "/contact-us",
  "/about-us",
];
