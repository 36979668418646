import { useState } from "react";
import { Box, Button } from "@mui/material";
// import LogoutIcon from "@mui/icons-material/Logout";
import LogoutDialog from "../../../components/dialog/logoutDialog";
import Translations from "../Translations";
import LogoutIcon from "../../../@core/component/icons/logoutIcon";
function AfterNavItemContent() {
  const [open, setOpen] = useState(false);
  const handleLogoutDialog = () => {
    setOpen((prev) => !prev);
  };
  return (
    <>
      <Box>
        <Button
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            borderRadius: "14px",
            color: "#5E6B6A",
            fontWeight: 600,
            paddingTop: "17px",
            paddingBottom: "12px",
            gap: "15px",
            marginLeft:"12px"
          }}
          startIcon={
            <div style={{ marginLeft: "10px" }}>
              <LogoutIcon />
            </div>
          }
          onClick={handleLogoutDialog}
        >
          <Translations text={"Logout"} />
        </Button>
      </Box>

      <LogoutDialog open={open} toggle={handleLogoutDialog} />
    </>
  );
}
export default AfterNavItemContent;
