// ** React Imports
import { createContext, useEffect, useState } from "react";

// ** Next Import
import { useNavigate, useSearchParams } from "react-router-dom";

// ** Config
import authConfig from "../configs/auth";
import { axiosInstance } from "../network/config/adapter";
import { Routes } from "../constant/routes";

import FallbackSpinner from "../@core/component/spinner";
import { ApiEndPoints } from "../network/endpoints";
import { enqueueSnackbar } from "notistack";

// ** Defaults
const defaultProvider = {
  unReadNotificationCount: 0,
  setUnReadNotificationCount: 0,
  isLogIn: false,
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
    
  getUnReadNotificationCount: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
};
const AuthContext = createContext(defaultProvider);

const AuthProvider = ({ children }) => {
  // ** States
  const [userToken, setUserToken] = useState(
    window.localStorage.getItem(authConfig.storageTokenKeyName)
  );
  const [user, setUser] = useState(defaultProvider.user);
  const [loading, setLoading] = useState(defaultProvider.loading);
  const [isInitialized, setIsInitialized] = useState(
    defaultProvider.isInitialized
  );
  const [isLogIn, setIsLogIn] = useState(defaultProvider.isLogIn);
  const [unReadNotificationCount, setUnReadNotificationCount] = useState(
    defaultProvider.unReadNotificationCount
  );

  console.log("DEBUGunReadNotificationCount", unReadNotificationCount);

  // ** Hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirectUrl = searchParams.get("redirect_url");
  console.log("redirectUrl", redirectUrl);

  const getUnReadNotificationCount = () => {
    axiosInstance
      .get(ApiEndPoints.NOTIFICATIONS.get_unread_notification_count)
      .then((response) => response.data)
      .then((response) => {
        console.log("getUnReadNotificationCount", response);
        setUnReadNotificationCount(response?.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    const initAuth = async () => {
      setIsInitialized(true);
      const storedToken = window.localStorage.getItem(
        authConfig.storageTokenKeyName
      );
      if (storedToken) {
        setLoading(true);
        await axiosInstance
          .get(ApiEndPoints.USER.me)
          .then((response) => response.data)
          .then(async (response) => {
            setLoading(false);
            setUser({ ...response.user });
            // Fetch un-read notification count
            getUnReadNotificationCount();
          })
          .catch(() => {
            setLoading(false);
            handleLogout();
          });
      } else {
        setLoading(false);
      }
    };
    initAuth();
  }, []);

  const handleLogin = (params, errorCallback) => {
    axiosInstance
      .post(ApiEndPoints.AUTH.login, params)
      .then((response) => response.data)
      .then(async (response) => {
        setIsLogIn(true);
        localStorage.setItem(authConfig.storageTokenKeyName, response.token);
        enqueueSnackbar(response.message, {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
      })
      .then(() => {
        const token = localStorage.getItem(authConfig.storageTokenKeyName);
        axiosInstance
          .get(ApiEndPoints.USER.me, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(async (response) => {
            // const returnUrl = searchParams.get("redirect_url");

            setUser({ ...response.data.user });
            await window.localStorage.setItem(
              "userData",
              JSON.stringify(response.data.user)
            );
            // console.log("redirect_url", returnUrl);
            // navigate(Routes.DASHBOARD);
          });
      })
      .catch((err) => {
        if (errorCallback) errorCallback(err);
        console.error(err);
      });
  };

  const handleLogout = () => {
    setIsLogIn(false);
    setUser(null);
    setIsInitialized(false);
    setUser(null);
    window.localStorage.removeItem("userData");
    localStorage.removeItem(authConfig.storageTokenKeyName);
    navigate(Routes.HOME);
  };

  const onOtpVerify = ({ payload }, errorCallback) => {
    axiosInstance
      .post(ApiEndPoints.AUTH.verify_otp, payload)
      .then((response) => {
        let token = response.data.token;
        localStorage.setItem(authConfig.storageTokenKeyName, token);
        setUser(response.data.user);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
        getUnReadNotificationCount();

        // Fetch un-read notification count
      })
      .then(() => {
        const token = localStorage.getItem(authConfig.storageTokenKeyName);
        axiosInstance
          .get(ApiEndPoints.USER.me, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(async (response) => {
            const returnUrl = searchParams.get("redirect_url");
            console.log("redirectUrl", redirectUrl);
            setUser({ ...response.data.user });
            await window.localStorage.setItem(
              "userData",
              JSON.stringify(response.data.user)
            );
            const redirectURL =
              returnUrl && returnUrl !== "/" ? returnUrl : "/";
            if (returnUrl) {
              navigate(redirectURL);
              console.log("returnUrlYes");
            } else {
              navigate(Routes.DASHBOARD);
              console.log("returnUrlNo");
            }
          });
        // navigate(Routes.DASHBOARD);
      })
      .catch((err) => (errorCallback ? errorCallback(err) : null));
  };

  const values = {
    userToken,
    user,
    loading,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout,
    otpVerify: onOtpVerify,
    unReadNotificationCount,
    setUnReadNotificationCount,
    getUnReadNotificationCount: getUnReadNotificationCount,
  };

  return (
    <AuthContext.Provider value={values}>
      {loading ? <FallbackSpinner /> : children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
