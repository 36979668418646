import _ from 'lodash'

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import { axiosInstance } from '../../../network/config/adapter'
import { ApiEndPoints } from '../../../network/endpoints'

// ** Fetch User Profile
// export const fetchUserProfile = createAsyncThunk('appChat/fetchUserProfile', async () => {
//   const response = await axiosInstance.get(ApiEndPoints.SELLER.get_buy_request)
//   return response?.data
// })

// export const filterBuyRequestData = createAsyncThunk('appChat/filterBuyReq', async (data, { dispatch }) => {
//   await dispatch(fetchChatsContacts(data))
// await dispatch(getChats({ buyRequestId: chat._id, channelId: `${chat._id}_${chat.as}` }))
// const response = await axiosInstance.get(ApiEndPoints.COMMENTS.get_comments(chat._id, `${chat._id}_${chat.as}`),)
// return response?.data
// })

// ** Fetch Chats & Contacts
export const searchChatsContacts = createAsyncThunk('appChat/searchChatsContacts', async (data, { dispatch }) => {
  // const response = await axiosInstance.get(ApiEndPoints.SELLER.get_buy_request)
  const response = await axiosInstance.get(ApiEndPoints.SELLER.get_buy_request, { params: { search: data?.search || "", page: data?.page || "" } })
  return response?.data?.buyRequests.map((br) => ({ ...br, id: br._id }))
})

export const fetchChatsContacts = createAsyncThunk('appChat/fetchChatsContacts', async (data, { dispatch }) => {
  // const response = await axiosInstance.get(ApiEndPoints.SELLER.get_buy_request)
  const response = await axiosInstance.get(ApiEndPoints.SELLER.get_buy_request, { params: { search: data?.search || "", page: data?.page || "" } })
  return response?.data
})

// ** Select Chat
export const selectChat = createAsyncThunk('appChat/selectChat', async (chat, { dispatch }) => {
  await dispatch(setSelectedChat(chat))
  await dispatch(getChats({ buyRequestId: chat._id, channelId: `${chat._id}_${chat.as}` }))
  // const response = await axiosInstance.get(ApiEndPoints.COMMENTS.get_comments(chat._id, `${chat._id}_${chat.as}`),)
  // return response?.data
})

// ** Fetch Chat
export const getChats = createAsyncThunk('appChat/getChats', async ({ buyRequestId, channelId, timeStamp }, { dispatch }) => {
  const response = await axiosInstance.get(ApiEndPoints.COMMENTS.get_comments(buyRequestId, channelId),
    { params: { last_message_timestamp: timeStamp } })
  return { ...response.data, messages: _.sortBy(response.data.messages, ["timestamp"]) }
})

// ** Send Msg
export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj, { dispatch }) => {
  await axiosInstance.post(ApiEndPoints.COMMENTS.get_comments(obj._id, `${obj._id}_${obj.as}`), { text: obj.text })
  await dispatch(getChats({ buyRequestId: obj._id, channelId: `${obj._id}_${obj.as}` }))
})

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    buyRequests: [],
    totalPages: { totalPages: "" },
    chats: { messages: [] },
    contacts: null,
    userProfile: null,
    selectedChat: null,
    loadMoreFlag: false,
  },
  reducers: {
    setSelectedChat: (state, action) => {
      state.selectedChat = action.payload;
    },
    removeSelectedChat: state => {
      state.selectedChat = null;
      state.chats = { messages: [] };
    },
    clearChats: state => {
      state.chats = { messages: [] };
    },
    resetChats: (state) => {
      state.buyRequests = [];
      state.totalPages = { totalPages: "" };
      state.chats = { messages: [] }
      state.contacts = null
      state.userProfile = null
      state.selectedChat = null
      state.loadMoreFlag = false
    }
  },
  extraReducers: builder => {
    // builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
    //   state.userProfile = action.payload
    // })
    builder.addCase(searchChatsContacts.fulfilled, (state, action) => {
      state.buyRequests = [...action.payload]
    })
    builder.addCase(fetchChatsContacts.fulfilled, (state, action) => {
      let newBuyRequests = [...state.buyRequests, ...action.payload.buyRequests.map((br) => ({ ...br, id: br._id }))]

      let result = newBuyRequests.reduce((acc, curr) => {
        return { ...acc, [curr._id]: curr }
      }, {})

      if (action.payload.totalPages >= 1) {
        state.loadMoreFlag = true;
      }
      if (action.payload.page === action.payload.totalPages) {
        state.loadMoreFlag = false;
      }



      state.buyRequests = Object.entries(result).map(i => i[1])
      state.totalPages = { totalPages: action.payload.totalPages, ...action.payload };
    })
    builder.addCase(getChats.fulfilled, (state, action) => {
      // state.chats = action.payload
      state.chats = { ...action.payload, messages: [...action.payload.messages] }
    })
  }
})

export const { removeSelectedChat, setSelectedChat, clearChats, resetChats } = appChatSlice.actions

export default appChatSlice.reducer
