import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import MenuIcon from "@mui/icons-material/Menu";
import Logo_Thanawi from "../../assets/images/logo_Thanawi.svg";
import lockLoginIcon from "../../assets/images/lockIcon.svg";
import theme from "../../components/customTheme/theme";
import { useNavigate, Link as RLink } from "react-router-dom";

import { Link, animateScroll as scroll } from "react-scroll";

const AppBarWrapper = styled(AppBar)(({ scroll }) => ({
  backgroundColor: scroll ? "#FFFFFF" : "transparent",
  transition: "background-color 0.3s ease-in-out",
  zIndex: 1100,
  left: "auto",
  right: "0px",
}));

const PublicLayoutAppBar = () => {
  const navigate = useNavigate();

  const imageSize = useMediaQuery("(max-width:730px)");
  const loginRegisterButton = useMediaQuery(
    "(min-width:600px) and (max-width:675px"
  );
  const [scroll, setScroll] = useState(false);
  console.log("DEBUG scroll", scroll);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorE2);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuLoginRegister = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseLoginRegister = () => {
    setAnchorE2(null);
  };

  const handleRegister = () => {
    navigate("/register");
  };
  const handleLogin = () => {
    navigate("/login");
  };

  const navItem = [
    { id: 0, name: "Home", path: "home", tagName: Link },
    { id: 1, name: "Offer and Transfer", path: "sellAndBuy", tagName: Link },
    { id: 3, name: "About Us", path: "/about-us", tagName: RLink },
    {
      id: 4,
      name: "KnowledgeHub",
      path: "OpportunitySection",
      tagName: Link,
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBarWrapper scroll={scroll} elevation={0} position="fixed">
      <Toolbar
        sx={{
          marginInline: { xs: "1rem", sm: "1rem", md: "5rem" },
          minHeight: "90px",
          paddingLeft: "0px !important",
          paddingRight: "0px !important",
        }}
      >
        <Box>
          <img
            src={Logo_Thanawi}
            alt="thanawi_Logo"
            style={{ height: imageSize ? 30 : 50, width: "auto" }}
          />
        </Box>
        <Box
          display={{
            xs: "none",
            sm: "flex",
            md: "flex",
          }}
          justifyContent={loginRegisterButton ? "center" : "end"}
          sx={{
            textAlign: "center",
            flexGrow: 1,
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "23.44px",
            letterSpacing: "-1px",
            color: "#7B7B7B",
          }}
        >
          <List>
            <ListItem
              sx={{
                justifyContent: "space-between",
                color: theme.palette.text.primary,
                paddingX: 0,
                // fontSize: "40px",
              }}
            >
              {navItem.map((item) => {
                return (
                  <item.tagName
                    key={item.id}
                    to={item.path}
                    {...(item.tagName === Link
                      ? { activeClass: "active", spy: true, smooth: true }
                      : {})}
                    style={{
                      textDecoration: item.tagName === RLink ? "none" : null,
                      cursor: "pointer",
                    }}
                  >
                    <ListItemText
                      primary={item.name}
                      primaryTypographyProps={{
                        sx: {
                          fontSize: { sm: "12px", md: "18px" },
                          fontWeight: 500,
                          transition: "transform .2s",
                          color: "primary.main",
                          fontFamily: "Cairo, sans-serif",
                          "&:hover": {
                            color: "#1C355E",
                            fontWeight: "bold",
                            // transform: "scale(1.5)",
                          },
                        },
                      }}
                      sx={{ margin: "0 20px" }}
                    />
                  </item.tagName>
                );
              })}
            </ListItem>
          </List>
        </Box>
        {loginRegisterButton ? (
          <Box flexGrow={0}>
            <Button
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenuLoginRegister}
              sx={{ color: "black", minWidth: "40px" }}
            >
              <MenuIcon />
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorE2}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open1}
              onClose={handleCloseLoginRegister}
            >
              <MenuItem>
                <Button
                  variant="mySuccess"
                  onClick={handleLogin}
                  sx={{
                    backgroundColor: theme.palette.common.color,
                    color: theme.palette.primary.main,
                    minWidth: "60px",
                    fontSize: { sm: "11px", md: "15px" },
                    textDecoration: "none",
                    fontFamily: "Cairo, sans-serif",
                  }}
                  startIcon={<img src={lockLoginIcon} alt="startIcon" />}
                >
                  Login
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  variant="myfailing"
                  onClick={handleRegister}
                  sx={{
                    color: theme.palette.primary.main,
                    border: `1px solid ${theme.palette.primary.main}`,
                    fontWeight: "bold",
                    minWidth: "60px",
                    textDecoration: "none",
                    "&:hover": {
                      backgroundColor: "primary.main",
                      color: "common.white",
                    },
                  }}
                >
                  Register
                </Button>
              </MenuItem>
            </Menu>
          </Box>
        ) : (
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            flexGrow={1}
            gap={1}
          >
            <Button
              variant="mySuccess"
              onClick={handleLogin}
              sx={{
                backgroundColor: theme.palette.common.color,
                color: theme.palette.primary.main,
                minWidth: "20px",
                display: { xs: "none", sm: "flex", md: "flex" },
                fontFamily: "Cairo, sans-serif",
                fontSize: { sm: "11px", md: "15px" },
                textDecoration: "none",
                fontWeight: 500,
              }}
              //   startIcon={<img src={lockLoginIcon} alt="startIcon" />}
            >
              Login
            </Button>
            <Button
              variant="myfailing"
              onClick={handleRegister}
              sx={{
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
                fontWeight: "bold",
                minWidth: "20px",
                textDecoration: "none",
                display: { xs: "none", sm: "flex", md: "flex" },
                fontSize: { sm: "10px", md: "15px" },
                // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                // transition: "box-shadow 0.3s ease",

                "&:hover": {
                  color: theme.palette.common.white,
                  backgroundColor: theme.palette.primary.main,
                  boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.3)",
                },
              }}
            >
              Register
            </Button>
          </Box>
        )}

        <Box display={{ xs: "flex", sm: "none", md: "none" }} flexGrow={0}>
          <Button
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            sx={{ color: "black", minWidth: "40px" }}
          >
            <MenuIcon />
          </Button>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <Link
              to="SellInEasySteps"
              activeClass="active"
              spy={true}
              smooth={true}
            >
              <MenuItem onClick={handleClose}>Home</MenuItem>
            </Link>
            <Link to="sellAndBuy" activeClass="active" spy={true} smooth={true}>
              <MenuItem onClick={handleClose}>Offer and Transfer</MenuItem>
            </Link>
            {/* <Link to="sellAndBuy" activeClass="active" spy={true} smooth={true}>
              <MenuItem onClick={handleClose}>Sell</MenuItem>
            </Link> */}
            <RLink to="/about-us" style={{ textDecoration: "none" }}>
              <MenuItem onClick={handleClose}>About</MenuItem>
            </RLink>
            <Link
              to="OpportunitySection"
              activeClass="active"
              spy={true}
              smooth={true}
            >
              <MenuItem onClick={handleClose}>Knowledge Hub</MenuItem>
            </Link>
            {/* <MenuItem onClick={handleClose}>MarketPlace</MenuItem> */}
            <MenuItem onClick={handleLogin}>Login</MenuItem>
            <MenuItem onClick={handleRegister}>Register</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBarWrapper>
  );
};

export default PublicLayoutAppBar;
