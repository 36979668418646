import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

import Footer from '../version2/footer'
import PublicLayoutAppBar from '../version2/header'

const PublicLayoutWrapper = styled(Box)(({ theme }) => ({}))

const PublicLayout = props => {
    const { children } = props
    return (
        <PublicLayoutWrapper>
            <PublicLayoutAppBar />
            <Box
                sx={{
                    overflowX: 'hidden',
                    position: 'relative',
                    minHeight: theme => `calc(100vh - ${theme.spacing(theme.mixins.toolbar.minHeight / 4)})`
                }}
            >
                {children}
            </Box>
            <Footer />
        </PublicLayoutWrapper>
    )
}
export default PublicLayout