// ** React Imports
import { createContext, useEffect, useState } from "react";

// ** Next Import
import { useNavigate, useSearchParams } from "react-router-dom";

// ** Config
import { axiosInstance } from "../network/config/adapter";

import { ApiEndPoints } from "../network/endpoints";

// ** Defaults
const defaultProvider = {
  ownershipTypes: [],
  companyTypes: [],
  salePurposes: [],
  freeZoneArea: [],
  loading: true,
  MISCAPI: () => Promise.resolve(),
};
const MiscContext = createContext(defaultProvider);

const MiscProvider = ({ children }) => {
  // ** States
  const [loading, setLoading] = useState(defaultProvider.loading);
  const [ownershipTypes, setOwnershipTypes] = useState(
    defaultProvider.ownershipTypes
  );
  const [companyTypes, setCompanyTypes] = useState(
    defaultProvider.companyTypes
  );
  const [salePurposes, setSalePurposes] = useState(
    defaultProvider.salePurposes
  );
  const [freeZoneArea, setFreeZoneArea] = useState(
    defaultProvider.freeZoneArea
  );

  const [paymentDetails, setPaymentDetails] = useState(null);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await axiosInstance
        .get(ApiEndPoints.MISC.allData)
        .then((response) => response.data)
        .then((response) => {
          setOwnershipTypes(response.ownershipTypes);
          setCompanyTypes(response.companyTypes);
          setSalePurposes(response.salePurposes);
          setPaymentDetails(response.paymentDetails);
          setFreeZoneArea(response.freeZoneAreas);
          console.log("MISSC", response.freeZoneAreas);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    init();
  }, []);


  const MISCAPI = async () => {
    setLoading(true);
    await axiosInstance
      .get(ApiEndPoints.MISC.allData)
      .then((response) => response.data)
      .then((response) => {
        setOwnershipTypes(response.ownershipTypes);
        setCompanyTypes(response.companyTypes);
        setSalePurposes(response.salePurposes);
        setPaymentDetails(response.paymentDetails);
        setFreeZoneArea(response.freeZoneAreas);
        console.log("MISSC", response.freeZoneAreas);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const values = {
    loading,
    ownershipTypes,
    companyTypes,
    salePurposes,
    paymentDetails,
    freeZoneArea,
    MISCAPI: MISCAPI
  };

  return <MiscContext.Provider value={values}>{children}</MiscContext.Provider>;
};

export { MiscContext, MiscProvider };
