import Avatar from "@mui/material/Avatar";
import BellIcon from "../../../../assets/images/bellIcon.svg";
import NotificationHeaderIcon1 from "../../../../assets/images/notificationHeaderIcon1.svg";
import { Badge, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
const AvatarStyle = {
  borderRadius: "200px",
  border: "1px solid #D9DFE8",
  backgroundColor: "#F1F5F9",
};
function Notification() {
  const navigate = useNavigate();
  const { unReadNotificationCount } = useAuth();

  console.log(
    "Notificaton Compo unReadNotificationCount",
    unReadNotificationCount
  );

  const handleClick = () => {
    navigate("/settings/main/notification");
  };

  return (
    <>
      <IconButton onClick={handleClick} sx={{ p: 0 }}>
        <Badge
          color="error"
          overlap="circular"
          variant="dot"
          invisible={unReadNotificationCount > 0 ? false : true}
        >
          <Avatar src={NotificationHeaderIcon1} alt="Notification Icon" />
        </Badge>
      </IconButton>
    </>
  );
}
export default Notification;
