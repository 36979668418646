// ** React Imports
import { useState } from "react";

// ** MUI Imports
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

// ** Icons Imports
import ArrowUp from "@mui/icons-material/ArrowUpward";

// ** Theme Config Import
import themeConfig from "../../configs/themeConfig";

// ** Components
import AppBar from "./components/vertical/appBar";
import Navigation from "./components/vertical/navigation";
// import Footer from "../../components/navigation/footer";
// import Footer from './components/shared-components/footer'
// import ScrollToTop from 'src/@core/components/scroll-to-top'

// ** Styled Component
import DatePickerWrapper from "../styles/libs/react-datepicker";
import Footer from "../../version2/footer";
import { Grid } from "@mui/material";

const VerticalLayoutWrapper = styled("div")({
  height: "100%",
  display: "flex",
  marginBottom: "100px",
});

const MainContentWrapper = styled(Box)({
  flexGrow: 1,
  minWidth: 0,
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
});

const ContentWrapper = styled("main")(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  // padding: theme.spacing(6),
  transition: "padding .25s ease-in-out",
  // [theme.breakpoints.down('sm')]: {
  //   paddingLeft: theme.spacing(4),
  //   paddingRight: theme.spacing(4)
  // }
}));

const VerticalLayout = (props) => {
  // ** Props
  const { hidden, settings, children, scrollToTop } = props;

  // ** Vars
  const { skin, navHidden, contentWidth } = settings;
  const { navigationSize, collapsedNavigationSize } = themeConfig;
  const navWidth = navigationSize;
  const navigationBorderWidth = skin === "bordered" ? 1 : 0;
  const collapsedNavWidth = collapsedNavigationSize;

  // ** States
  const [navHover, setNavHover] = useState(false);
  const [navVisible, setNavVisible] = useState(false);

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible);

  console.log("navHidden", navHidden);

  return (
    <>
      <Box display={"flex"} flexDirection={"column"}>
        <AppBar toggleNavVisibility={toggleNavVisibility} {...props} />
        <VerticalLayoutWrapper className="layout-wrapper">
          {/* <Grid container justifyContent={"flex-end"}> */}
          {/* <Grid item xs={hidden ? 0 : 1.5} display={"flex"} justifyContent={"flex-end"}> */}
          {navHidden &&
          themeConfig.layout === "vertical" &&
          !(navHidden && settings.lastLayout === "horizontal") ? null : (
            <Navigation
              navWidth={navWidth}
              navHover={navHover}
              navVisible={navVisible}
              setNavHover={setNavHover}
              setNavVisible={setNavVisible}
              collapsedNavWidth={collapsedNavWidth}
              toggleNavVisibility={toggleNavVisibility}
              navigationBorderWidth={navigationBorderWidth}
              {...props}
            />
          )}
          {/* </Grid> */}
          {/* <Grid item xs={hidden ? 12 : 10.5} display={"flex"} justifyContent={"flex-end"}> */}
          <MainContentWrapper className="layout-content-wrapper">
            <ContentWrapper
              className="layout-page-content"
              sx={{
                gap: 1,
                display: "flex",
                flexDirection: "column",
                mx: "auto",
                maxWidth: "90%",
                // "@media (min-width:1440px)": { maxWidth: 1440 },
                // "@media (min-width:1200px)": { maxWidth: "100%" },
              }}
            >
              <div style={{ flex: "1" }}>{children}</div>
            </ContentWrapper>
            {/* <DatePickerWrapper sx={{ zIndex: 11 }}>
            <Box id="react-datepicker-portal"></Box>
          </DatePickerWrapper> */}
          </MainContentWrapper>
          {/* </Grid> */}
          {/* </Grid> */}
        </VerticalLayoutWrapper>
        <Footer {...props} />
      </Box>
      {/* {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className='mui-fixed'>
          <Fab color='primary' size='small' aria-label='scroll back to top'>
            <ArrowUp />
          </Fab>
        </ScrollToTop>
      )} */}
    </>
  );
};

export default VerticalLayout;
