import { Search } from '@mui/icons-material'
import { Avatar, Badge, Box, InputBase } from '@mui/material'
import { useState } from 'react'
import styled from 'styled-components'
import SearchEventDialog from '../../../../components/dialog/SearchEventDialog'

const SearchContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  background: '#ffffff',
  border: '1px solid rgba(80, 80, 80, 0.20)',
  borderRadius: '200px',
  padding: '3px 10px'
})

const SearchIcon = styled(Search)({
  marginRight: 8,
  color: '#303A4B'
})

const SearchInput = styled(InputBase)({
  flex: 1
})

const AvatarStyle = {
  borderRadius: '200px',
  border: '1px solid rgba(80, 80, 80, 0.20)',
  backgroundColor: '#ffffff',
  display: "flex",
  justifyContent: "flex-end"
}

function SearchBar() {

  const [isSearchDialogOpen, setSearchDialogOpen] = useState(false)

  const toggleSearchDialog = () => {
    setSearchDialogOpen(prev => !prev);
  }

  return (
    <>
      <div onClick={toggleSearchDialog}>
        <Box display={{ xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }} >
          <SearchContainer>
            <SearchIcon />
            <SearchInput placeholder='Search Here...' />
          </SearchContainer>
        </Box>
      </div>
      <div onClick={toggleSearchDialog}>

        <Badge color='secondary' badgeContent={0} sx={{ display: { xs: "flex", sm: "flex", md: "none", lg: "none", xl: "none" } }}>
          <Avatar alt='Remy Sharp' sx={{ ...AvatarStyle }}>
            <SearchIcon />
          </Avatar>
        </Badge>
      </div>

      <SearchEventDialog
        open={isSearchDialogOpen}
        toggle={toggleSearchDialog}
      />

    </>
  )
}
export default SearchBar
