import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import Translations from "../Translations";
import { useNavigate } from "react-router-dom";

function AfterNavMenuContent(props) {
  const navigate = useNavigate();

  const handleNavigateSubscription = () => {
    navigate("/coming-soon");
  };

  return (
    <Box
      sx={{ padding: "18px", width: "100%" }}
      display="flex"
      alignItems="flex-end"
      justifyContent={"center"}
    >
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          borderRadius: "15px",
          background: "#007167",
          textTransform: "uppercase",
        }}
        // sx={{ ...cardBoxStyle }}
      >
        <CardContent
          sx={{
            padding: "16px 16px 0 16px",
            color: "#ffffff",
            textAlign: "center",
          }}
        >
          <Typography
            fontWeight={700}
            // sx={{ ...subscriptionStyle }}
            gutterBottom
            color={"common.white"}
          >
            <Translations text={"Monthly"} />
          </Typography>
          <Typography
            fontWeight={700}
            // sx={{ ...subscriptionStyle }}
            gutterBottom
            color={"common.white"}
          >
            <Translations text={"Payment"} />
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant="outlined"
            fullWidth
            onClick={handleNavigateSubscription}
            sx={{
              background: "#ffffff",
              color: "#007167",
              textTransform: "uppercase",
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "#ffffff",
              },
            }}
          >
            <Translations text={"Subscribe"} />
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export default AfterNavMenuContent;
