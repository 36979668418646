// ** Theme Config Imports
import { experimental_extendTheme } from '@mui/material/styles'
import themeConfig from '../../../configs/themeConfig'

const Button = theme => {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 175,
                    fontSize: 16,
                    fontWeight: 600,
                    letterSpacing: 0.16,
                    borderRadius: 12,
                    textTransform: "capitalize",
                },
                contained: {},
                containedDark: experimental_extendTheme(({ palette }) => ({
                    // Extend 'contained' variant styles and override background color
                    backgroundColor: "#000000",
                    color: palette.getContrastText("#0e0e0e"), // Set text color for better contrast
                })),
                // contained: {
                //     boxShadow: theme.shadows[3],
                //     padding: `${theme.spacing(1.875, 5.5)}`
                // },
                // outlined: {
                //     padding: `${theme.spacing(1.625, 5.25)}`
                // },
                // sizeSmall: {
                //     padding: `${theme.spacing(1, 2.25)}`,
                //     '&.MuiButton-contained': {
                //         padding: `${theme.spacing(1, 3.5)}`
                //     },
                //     '&.MuiButton-outlined': {
                //         padding: `${theme.spacing(0.75, 3.25)}`
                //     }
                // },
                // sizeLarge: {
                //     padding: `${theme.spacing(2.125, 5.5)}`,
                //     '&.MuiButton-contained': {
                //         padding: `${theme.spacing(2.125, 6.5)}`
                //     },
                //     '&.MuiButton-outlined': {
                //         padding: `${theme.spacing(1.875, 6.25)}`
                //     }
                // }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: themeConfig.disableRipple
            }
        }
    }
}

export default Button