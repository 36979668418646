// ** Overrides Imports
import MuiButton from './button'

const Overrides = (theme, settings) => {
  const { skin } = settings
  const button = MuiButton(theme)

  return Object.assign(
    button,
  )
}

export default Overrides
