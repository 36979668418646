// ** MUI Imports
import { Box, Button, IconButton, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import MuiSwipeableDrawer from "@mui/material/SwipeableDrawer";
import SideBarArrowRight from "../../../../../assets/images/sidebarArrow.svg";
import SideBarArrowLeft from "../../../../../assets/images/SideBarArrowLeft.svg";

const SwipeableDrawer = styled(MuiSwipeableDrawer)({
  margin: '15px 60px',
  // paddingLeft: 15,
  overflowX: "hidden",
  // transition: "width .25s ease-in-out, .25s ease-in-out",
  "& ul": {
    listStyle: "none",
  },
  "& .MuiListItem-gutters": {
    // paddingLeft: 4,
    // paddingRight: 4,
  },
  "& .MuiDrawer-paper": {
    filter: "drop-shadow(10px 10px 10px #e6e9ee)",
    // top: "15%",
    // left: "5%",
    // right: "unset",
    overflowX: "hidden",
    transition: "width .25s ease-in-out, .25s ease-in-out",
    // height: "calc(100% - 300px)",
    borderRadius: 30,
  },
});

const Drawer = (props) => {
  // ** Props
  const {
    hidden,
    children,
    navHover,
    navWidth,
    settings,
    navVisible,
    setNavHover,
    setNavVisible,
    collapsedNavWidth,
    navigationBorderWidth,
  } = props;

  // ** Hook
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-height:700px)');

  // ** Vars
  const { skin, navCollapsed } = settings;

  const drawerColor = () => {
    if (skin === "semi-dark" && theme.palette.mode === "light") {
      return {
        "& .MuiTypography-root, & .MuiSvgIcon-root": {
          color: `rgba(${theme.palette.customColors.dark}, 0.87)`,
        },
      };
    } else if (skin === "semi-dark" && theme.palette.mode === "dark") {
      return {
        "& .MuiTypography-root, & .MuiSvgIcon-root": {
          color: `rgba(${theme.palette.customColors.light}, 0.87)`,
        },
      };
    } else return {};
  };

  const drawerBgColor = () => {
    if (skin === "semi-dark" && theme.palette.mode === "light") {
      return {
        // backgroundColor: theme.palette.customColors.darkBg,
        background: "#f1f5f9"
      };
    } else if (skin === "semi-dark" && theme.palette.mode === "dark") {
      return {
        // backgroundColor: theme.palette.customColors.lightBg,
        background: "#f1f5f9"
      };
    } else {
      return {
        // backgroundColor: theme.palette.background.default,
        background: "#f1f5f9"
      };
    }
  };

  // Drawer Props for Mobile & Tablet screens
  const MobileDrawerProps = {
    open: navVisible,
    onOpen: () => setNavVisible(true),
    onClose: () => setNavVisible(false),
    ModalProps: {
      keepMounted: true, // Better open performance on mobile.
    },
  };

  // Drawer Props for Desktop screens
  const DesktopDrawerProps = {
    // open: true,
    onOpen: () => null,
    onClose: () => null,
    // onMouseEnter: () => {
    //   setNavHover(true);
    // },
    // onMouseLeave: () => {
    //   setNavHover(false);
    // },
  };

  return (
    <>
      <SwipeableDrawer
        // hideBackdrop={true}
        className="layout-vertical-nav"
        variant={hidden ? "temporary" : "permanent"}
        {...(hidden ? { ...MobileDrawerProps } : { ...DesktopDrawerProps })}
        PaperProps={{
          sx: { width: navCollapsed && !navHover ? collapsedNavWidth : navWidth },
        }}
        sx={{
          width: navCollapsed ? collapsedNavWidth : navWidth,
          "& .MuiDrawer-paper": {
            // height: isSmallScreen ? "100%" : "600px",
            // overflowY: isSmallScreen ? 'auto' : 'initial', // Enable vertical scrolling
            // marginLeft: 100,
            ...drawerColor(),
            ...drawerBgColor(),
            ...(!hidden && navCollapsed && navHover ? { boxShadow: 9 } : {}),
            // borderRight: navigationBorderWidth === 0 ? 0 : `${navigationBorderWidth}px solid ${theme.palette.divider}`,


            // custom css
            maxHeight: 'calc(100vh - 150px)',
            height: 'auto',
            overflowY: 'auto',
            scrollbarWidth:"thin",
            top: 'unset',
            left: 'unset',

          },
        }}
      >
        {children}
      </SwipeableDrawer>
      {
        !hidden && <Box
          sx={{
            textAlign: 'end',
            position: 'fixed',
            margin: '15px 65px',
            overflowX: 'hidden',
            zIndex: 1201,
            marginTop: '55px',
            transition: 'width .25s ease-in-out, .25s ease-in-out',
            width: navCollapsed && !navHover ? collapsedNavWidth + 15 : navWidth + 15,
          }}
        >

          <IconButton

            onClick={() => setNavHover((prev) => !prev)}
          >

            <img src={navHover ? SideBarArrowLeft : SideBarArrowRight} alt="sidebar arrow" />
          </IconButton>
        </Box>
      }
    </>
  );
};

export default Drawer;
