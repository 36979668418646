// ** MUI Import
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const FallbackSpinner = (props) => {
  // ** Hook
  const { height } = props;

  return (
    <Box
      sx={{
        height: height ? height : "100vh",
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      {/* TODO: Add custom logo */}
      <CircularProgress disableShrink sx={{ mt: 6, color: "primary.main" }} />
    </Box>
  )
}

export default FallbackSpinner
