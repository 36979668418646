import { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

// ** Layout Imports
// !Do not remove this Layout import
import Layout from "../@core/layouts/Layout";

import VerticalNavItems from "../navigation/vertical";
import HorizontalNavItems from "../navigation/horizontal";

import VerticalAppBarContent from "./components/vertical/AppBarContent";
import HorizontalAppBarContent from "./components/horizontal/AppBarContent";

// ** Hook Import
import { useSettings } from "../@core/hooks/useSettings";
import AfterNavMenuContent from "./components/vertical/AfterNavMenuContent";
import AfterNavItemContent from "./components/vertical/AfterNavItemContent";

function UserLayout({ children }) {
  // ** Hooks
  const { settings, saveSettings } = useSettings();

  const [appBarHeight, setAppBarHeight] = useState(0);

  useEffect(() => {
    // Calculate the AppBar height and update the state
    const appBarElement = document.getElementById("app-bar");
    if (appBarElement) {
      setAppBarHeight(appBarElement.offsetHeight);
    }
  }, []);

  const hidden = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <>
      <Layout
        hidden={hidden}
        settings={settings}
        saveSettings={saveSettings}
        {...(settings.layout === "horizontal"
          ? {
              horizontalNavItems: HorizontalNavItems(),
              horizontalAppBarContent: () => (
                <HorizontalAppBarContent
                  hidden={hidden}
                  settings={settings}
                  saveSettings={saveSettings}
                />
              ),
            }
          : {
              verticalNavItems: VerticalNavItems(),
              afterNavItemContent: AfterNavItemContent,
              verticalAppBarContent: (props) => (
                <VerticalAppBarContent
                  hidden={hidden}
                  settings={settings}
                  saveSettings={saveSettings}
                  toggleNavVisibility={props.toggleNavVisibility}
                />
              ),
              afterVerticalNavMenuContent: (props) => (
                <AfterNavMenuContent {...props} />
              ),
            })}
      >
        {children}
      </Layout>
    </>
  );
}

export default UserLayout;

// afterNavItemContent={(props) => (
//   <Box paddingLeft={2} paddingRight={2}>
//     <Button
//       sx={{
//         display: "flex",
//         justifyContent: "flex-start",
//         alignItems: "center",
//         width: "100%",
//         borderRadius: "14px",
//         color: "#5E6B6A",
//         fontWeight: 600,
//         paddingTop: "12px",
//         paddingBottom: "12px",
//         paddingLeft: "32px",
//         paddingRight: "32px",
//         gap: "15px",
//       }}
//       startIcon={<LogoutIcon sx={{ ml: "10px", color: "#000" }} />}
//       onClick={handleLogoutDialog}
//     >
//       Logout
//     </Button>
//   </Box>
// )}
// afterVerticalNavMenuContent={(props) => (
//   <AfterNavMenuContent {...props} />
// )}
