function Activityicon({ strokes }) {
    return (
        <svg id="Group_135" data-name="Group 135" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_413" data-name="Rectangle 413" width="40" height="40" fill="#1c355e" />
                </clipPath>
            </defs>
            <g id="Group_134" data-name="Group 134" clip-path="url(#clip-path)">
                <path id="Path_833" data-name="Path 833" d="M34.222,14.222a5.7,5.7,0,0,0-3.1.908L24.87,8.877a5.7,5.7,0,0,0,.908-3.1,5.778,5.778,0,1,0-11.556,0,5.6,5.6,0,0,0,.051.749L10.646,7.848a5.777,5.777,0,1,0-3.06,8.587l1.926,3.242L7.786,22.345a5.68,5.68,0,0,0-2.008-.365,5.778,5.778,0,1,0,4.482,9.42l4.022,2.014a5.624,5.624,0,0,0-.06.809,5.778,5.778,0,1,0,10.822-2.814l6.188-6.471a5.71,5.71,0,0,0,2.989.84,5.778,5.778,0,1,0,0-11.556M5.778,31.03a3.273,3.273,0,1,1,3.273-3.273A3.276,3.276,0,0,1,5.778,31.03m0-23.354a3.273,3.273,0,1,1-3.273,3.273A3.276,3.276,0,0,1,5.778,7.677M20,37.495a3.273,3.273,0,1,1,3.273-3.273A3.276,3.276,0,0,1,20,37.495M9.89,23.7l1.051-1.624L15.8,30.263a5.762,5.762,0,0,0-.63.795l-3.794-1.9a5.679,5.679,0,0,0,.177-1.4A5.761,5.761,0,0,0,9.89,23.7m.369-9.114,1.925.962L11.032,17.33l-1.3-2.181a5.814,5.814,0,0,0,.522-.559M11.5,10.2l3.627-1.321a5.762,5.762,0,0,0,.757.951l-2.335,3.606-2.174-1.087a5.681,5.681,0,0,0,.176-1.4A5.6,5.6,0,0,0,11.5,10.2m8.5-7.7a3.273,3.273,0,1,1-3.273,3.273A3.276,3.276,0,0,1,20,2.505M18.747,23.7v4.889a5.715,5.715,0,0,0-.868.266L12.46,19.734l1.907-2.945a5.667,5.667,0,0,0-.146,1.272A5.786,5.786,0,0,0,18.747,23.7M20,14.788a3.273,3.273,0,1,1-3.273,3.273A3.276,3.276,0,0,1,20,14.788m5.758,2.792a5.776,5.776,0,0,0-9.221-4.143l1.455-2.247a5.727,5.727,0,0,0,5.106-.542L29.353,16.9a5.709,5.709,0,0,0-.545,1.1ZM21.253,28.586V23.7a5.742,5.742,0,0,0,4.163-3.636l3.05.419a5.792,5.792,0,0,0,.956,2.727l-6.053,6.329a5.731,5.731,0,0,0-2.116-.95m12.97-5.313A3.273,3.273,0,1,1,37.495,20a3.276,3.276,0,0,1-3.273,3.273" fill="#1c355e" />
            </g>
        </svg>

    );
}
export default Activityicon;
