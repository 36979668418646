function Settingsicon({ strokes }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 41.734 39.995">
  <g id="settings" transform="translate(0 -10.667)">
    <g id="Group_125" data-name="Group 125" transform="translate(0 37.989)">
      <g id="Group_124" data-name="Group 124">
        <path id="Path_824" data-name="Path 824" d="M39.995,356.526H35.364a5.983,5.983,0,0,0-11.605,0H1.739a1.812,1.812,0,0,0,0,3.621h22.02a5.983,5.983,0,0,0,11.605,0h4.631a1.812,1.812,0,0,0,0-3.621Zm-10.433,4.526a2.718,2.718,0,1,1,2.608-2.716A2.667,2.667,0,0,1,29.561,361.052Z" transform="translate(0 -352)" fill="#1c355e"/>
      </g>
    </g>
    <g id="Group_127" data-name="Group 127" transform="translate(0 10.667)">
      <g id="Group_126" data-name="Group 126" transform="translate(0 0)">
        <path id="Path_825" data-name="Path 825" d="M39.995,15.193H35.364a5.983,5.983,0,0,0-11.605,0H1.739a1.812,1.812,0,0,0,0,3.621h22.02a5.983,5.983,0,0,0,11.605,0h4.631a1.812,1.812,0,0,0,0-3.621ZM29.561,19.719A2.718,2.718,0,1,1,32.17,17,2.667,2.667,0,0,1,29.561,19.719Z" transform="translate(0 -10.667)" fill="#1c355e"/>
      </g>
    </g>
    <g id="Group_129" data-name="Group 129" transform="translate(0 24.328)">
      <g id="Group_128" data-name="Group 128">
        <path id="Path_826" data-name="Path 826" d="M39.995,185.86H17.975a6.115,6.115,0,0,0-5.8-4.526,6.115,6.115,0,0,0-5.8,4.526H1.739a1.812,1.812,0,0,0,0,3.621H6.37a6.115,6.115,0,0,0,5.8,4.526,6.115,6.115,0,0,0,5.8-4.526h22.02a1.812,1.812,0,0,0,0-3.621Zm-27.823,4.526a2.718,2.718,0,1,1,2.608-2.716A2.667,2.667,0,0,1,12.172,190.386Z" transform="translate(0 -181.334)" fill="#1c355e"/>
      </g>
    </g>
  </g>
</svg>


  );
}
export default Settingsicon;
