function SafenoteIcon({ strokes }) {
    return (

        <svg id="Group_137" data-name="Group 137" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40.001 40">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_414" data-name="Rectangle 414" width="40.001" height="40" fill="#1c355e" />
                </clipPath>
            </defs>
            <g id="Group_136" data-name="Group 136" clip-path="url(#clip-path)">
                <path id="Path_834" data-name="Path 834" d="M104.046,91.043a13.024,13.024,0,1,0,13.024,13.024,13.039,13.039,0,0,0-13.024-13.024m0,22.983a9.959,9.959,0,1,1,9.959-9.959,9.971,9.971,0,0,1-9.959,9.959" transform="translate(-84.047 -84.067)" fill="#1c355e" />
                <path id="Path_835" data-name="Path 835" d="M180.12,196.467a1.532,1.532,0,0,0-2.167,0l-5.811,5.811-3.513-3.513a1.532,1.532,0,1,0-2.167,2.167l4.6,4.6a1.532,1.532,0,0,0,2.167,0l6.895-6.895a1.532,1.532,0,0,0,0-2.167" transform="translate(-153.292 -180.997)" fill="#1c355e" />
                <path id="Path_836" data-name="Path 836" d="M39.707,23.627l-1.5-3.643,1.5-3.643a3.815,3.815,0,0,0-1.535-4.725h0L34.292,9.295,33.43,5.724A3.813,3.813,0,0,0,29.411,2.8L25,3.145,22.482.92a3.815,3.815,0,0,0-4.968,0l-2.52,2.224L10.586,2.8A3.821,3.821,0,0,0,6.58,5.667L5.374,9.51,1.828,11.617A3.815,3.815,0,0,0,.293,16.342l1.5,3.643-1.5,3.643a3.814,3.814,0,0,0,1.534,4.724L5.7,30.66l.866,3.586a3.815,3.815,0,0,0,3.717,2.932H10.3c.144,0,.144,0,1.159-.079.645-.05,1.706-.133,3.487-.27l2.568,2.253a3.817,3.817,0,0,0,4.969,0l2.566-2.252,4.361.337q.152.012.3.012a3.814,3.814,0,0,0,3.717-2.932l.862-3.572,3.767-2.254.088-.053.013-.008h0l.017-.011a3.815,3.815,0,0,0,1.529-4.721M29.647,34.111l-5-.387a1.542,1.542,0,0,0-1.129.376l-3.025,2.655a.764.764,0,0,1-.981,0L16.483,34.1a1.541,1.541,0,0,0-1.129-.376l-5,.387a.763.763,0,0,1-.8-.584L8.528,29.314a1.541,1.541,0,0,0-.705-.957L3.429,25.739a.763.763,0,0,1-.3-.943L4.87,20.569a1.523,1.523,0,0,0,0-1.169L3.125,15.173a.764.764,0,0,1,.3-.94l4.018-2.387a1.531,1.531,0,0,0,.679-.859c.6-1.9.94-2.995,1.138-3.625.274-.874.274-.874.285-.92a.763.763,0,0,1,.8-.584L15.4,6.25a1.541,1.541,0,0,0,1.132-.379l2.974-2.625a.765.765,0,0,1,.978,0l2.974,2.625a1.54,1.54,0,0,0,1.132.379l5.054-.391a.762.762,0,0,1,.8.584l1.013,4.2a1.543,1.543,0,0,0,.7.955l4.4,2.636a.763.763,0,0,1,.3.943L35.129,19.4a1.523,1.523,0,0,0,0,1.169L36.874,24.8a.762.762,0,0,1-.3.94l-4.409,2.639a1.542,1.542,0,0,0-.7.955l-1.013,4.2a.762.762,0,0,1-.8.584" transform="translate(0.001 -0.001)" fill="#1c355e" />
            </g>
        </svg>

    );
}
export default SafenoteIcon;
