function PortfolioIcon({ strokes }) {
  return (
    <svg id="Group_133" data-name="Group 133" xmlns="http://www.w3.org/2000/svg"  width="30" height="30" viewBox="0 0 40 40">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_412" data-name="Rectangle 412" width="40" height="40" fill="#1c355e" />
        </clipPath>
      </defs>
      <g id="Group_132" data-name="Group 132" clip-path="url(#clip-path)">
        <path id="Path_832" data-name="Path 832" d="M35.128,4.589H31.107V3.559A3.572,3.572,0,0,0,27.541,0H8.8A3.573,3.573,0,0,0,5.239,3.561V4.589H4.872A4.884,4.884,0,0,0,0,9.462V35.436A4.574,4.574,0,0,0,4.56,40H35.441A4.573,4.573,0,0,0,40,35.436V9.461a4.884,4.884,0,0,0-4.872-4.872M27.655,7.736h-14a3.577,3.577,0,0,0-3.539,3.151H8.69V3.564A.115.115,0,0,1,8.8,3.452H27.541a.114.114,0,0,1,.114.11Zm6.176.3h1.3a1.425,1.425,0,0,1,1.422,1.421v7.494a4.609,4.609,0,0,0-.589-.109V11.3a3.577,3.577,0,0,0-2.128-3.259m-1.323,8.776H24.379a.718.718,0,0,1-.643-.406L22.22,13.255A4.2,4.2,0,0,0,20,11.188H32.394a.116.116,0,0,1,.114.114ZM20.624,17.9a4.2,4.2,0,0,0,3.754,2.365H35.439a1.113,1.113,0,0,1,1.109,1.109V35.436a1.113,1.113,0,0,1-1.109,1.113H4.563a1.115,1.115,0,0,1-1.111-1.113V15.452A1.114,1.114,0,0,1,4.56,14.339h13.9a.72.72,0,0,1,.648.408ZM5.239,10.887H4.56a4.535,4.535,0,0,0-1.109.139V9.463A1.425,1.425,0,0,1,4.873,8.041h.365Z" fill="#1c355e" />
      </g>
    </svg>

  );
}
export default PortfolioIcon;
