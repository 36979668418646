// ** Icon imports
import DashboardIcon from '@mui/icons-material/Dashboard'
import WalletIcon from '@mui/icons-material/Wallet'
import DataThresholdingIcon from '@mui/icons-material/DataThresholding'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom'
import TuneIcon from '@mui/icons-material/Tune'
import LogoutIcon from '@mui/icons-material/Logout'
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';

// import OverviewIcon from "../../assets/images/overviewIcon.svg";
// import WalletIcon from "../../assets/images/walletIcon.svg";

const navigation = () => {
  return [
    {
      icon: DashboardIcon,
      title: 'Overview',
      path: '/dashboard'
    },
    {
      icon: WalletIcon,
      title: 'Wallet',
      path: '/wallet'
    },
    {
      icon: DataThresholdingIcon,
      title: 'Holdings',
      path: '/holdings'
    },
    {
      icon: DataThresholdingIcon,
      title: 'Seller',
      path: '/seller'
    },
    {
      icon: CreditCardIcon,
      title: 'Payment',
      path: '/payment'
    },
    {
      icon: AlignVerticalBottomIcon,
      title: 'Activity',
      path: '/activity'
    },
    {
      icon: TextsmsOutlinedIcon,
      title: 'Comment',
      path: '/comment'
    },
    {
      icon: TuneIcon,
      title: 'Settings',
      path: '/settings'
    }
    // {
    //   icon: LogoutIcon,
    //   title: "Logout",
    //   // path: "/logout",
    // },
  ]
}

export default navigation
