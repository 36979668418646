
function WalletIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 46.602 40.099">
      <g id="wallet" transform="translate(-1.25 -2.75)">
        <path id="Path_828" data-name="Path 828" d="M6.25,9.876A1.626,1.626,0,0,1,7.876,8.25H20.881a1.626,1.626,0,1,1,0,3.251H7.876A1.626,1.626,0,0,1,6.25,9.876Z" transform="translate(5.838 6.421)" fill="#1c355e" fill-rule="evenodd"/>
        <path id="Path_829" data-name="Path 829" d="M22.188,12.451a2.694,2.694,0,0,0-1.956.809l-.029.029a2.728,2.728,0,0,0-.818,2.216l0,.019a3,3,0,0,0,3.062,2.563h4.211a.6.6,0,0,0,.579-.585V13.037a.6.6,0,0,0-.579-.585Zm-4.272-1.474A5.947,5.947,0,0,1,22.188,9.2h4.567a3.851,3.851,0,0,1,3.735,3.836V17.5a3.851,3.851,0,0,1-3.735,3.835H22.448a6.247,6.247,0,0,1-6.3-5.534,5.979,5.979,0,0,1,1.769-4.827Z" transform="translate(17.362 7.531)" fill="#1c355e" fill-rule="evenodd"/>
        <path id="Path_830" data-name="Path 830" d="M13.713,6a9.91,9.91,0,0,0-1.508.111l-.045.006A8.521,8.521,0,0,0,6.581,8.963a9.432,9.432,0,0,0-2.08,6.25V30.386a9.223,9.223,0,0,0,2.5,6.708,9.223,9.223,0,0,0,6.708,2.5H33.221a9.223,9.223,0,0,0,6.708-2.5,9.223,9.223,0,0,0,2.5-6.708V28.869H39.81a6.246,6.246,0,0,1-6.3-5.534,6.092,6.092,0,0,1,6.041-6.6h2.883V15.213a9.415,9.415,0,0,0-2.114-6.286A8.58,8.58,0,0,0,34.659,6.1l-.08-.011A8.342,8.342,0,0,0,33.221,6ZM11.733,2.9a13.174,13.174,0,0,1,1.98-.146H33.221a11.619,11.619,0,0,1,1.852.124A11.816,11.816,0,0,1,42.781,6.8a12.652,12.652,0,0,1,2.9,8.41v3.143a1.626,1.626,0,0,1-1.626,1.626H39.55a2.694,2.694,0,0,0-1.957.809l-.029.029a2.727,2.727,0,0,0-.818,2.216l0,.019h0a3,3,0,0,0,3.062,2.563h4.248a1.626,1.626,0,0,1,1.626,1.626v3.143a12.46,12.46,0,0,1-3.457,9.007,12.46,12.46,0,0,1-9.007,3.457H13.713a12.459,12.459,0,0,1-9.007-3.457A12.46,12.46,0,0,1,1.25,30.386V15.213A12.668,12.668,0,0,1,4.107,6.854,11.761,11.761,0,0,1,11.733,2.9Z" transform="translate(0 0)" fill="#1c355e" fill-rule="evenodd"/>
      </g>
    </svg>
    )
}
export default WalletIcon;
