function CommentIcon({ strokes }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 39.94 39.94">
      <g id="comment" transform="translate(-60.041 -60.041)">
        <g id="Group_123" data-name="Group 123" transform="translate(60.041 60.041)">
          <g id="Group_118" data-name="Group 118" transform="translate(0 0)">
            <path id="Path_815" data-name="Path 815" d="M80.011,99.981H65.88a2.051,2.051,0,0,1-1.715-3.386l1.776-2.413a19.97,19.97,0,1,1,14.07,5.8Zm0-3.057a16.912,16.912,0,1,0-11.031-4.093l1.072.924-2.333,3.169Z" transform="translate(-60.041 -60.041)" fill="#1c355e" />
          </g>
          <g id="Group_122" data-name="Group 122" transform="translate(6.558 16.991)">
            <g id="Group_119" data-name="Group 119">
              <path id="Path_816" data-name="Path 816" d="M167.958,238.1a2.979,2.979,0,1,1-2.979-2.979A2.979,2.979,0,0,1,167.958,238.1Z" transform="translate(-162 -235.119)" fill="#1c355e" />
            </g>
            <g id="Group_120" data-name="Group 120" transform="translate(10.434 0)">
              <circle id="Ellipse_3" data-name="Ellipse 3" cx="2.979" cy="2.979" r="2.979" fill="#1c355e" />
            </g>
            <g id="Group_121" data-name="Group 121" transform="translate(20.867 0)">
              <circle id="Ellipse_4" data-name="Ellipse 4" cx="2.979" cy="2.979" r="2.979" fill="#1c355e" />
            </g>
          </g>
        </g>
      </g>
    </svg>

  );
}
export default CommentIcon;
