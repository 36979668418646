import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAuth } from "../../hooks/useAuth";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import Translations from "../../layouts/components/Translations";

const LogoutText = styled(Typography)(({ theme }) => ({
  fontFamily: "Cairo, sans-serif",
  fontSize: "22px",
  fontWeight: "bold",
  lineHeight: "45px",
  color: "#1C355E",
  textAlign: "left",
}));
const Buttons = styled(LoadingButton)(({ theme }) => ({
  fontFamily: "Cairo, sans-serif",
  fontSize: "18px",
  fontWeight: "bold",
  lineHeight: "25px",
  color: "#007167",
  textAlign: "left",
}));

function LogoutDialog({ open, toggle }) {
  const { logout } = useAuth();

  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    setLoading(true);
    setTimeout(() => {
      logout();
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        PaperProps={{ sx: { borderRadius: "30px" } }}
      >
        <DialogContent>
          <LogoutText>
            <Translations text={"Are you sure you want to Logout ?"} />
          </LogoutText>
        </DialogContent>
        <DialogActions>
          <Buttons onClick={toggle}>
            <Translations text={"Cancel"} />
          </Buttons>
          <Buttons loading={loading} onClick={handleLogout}>
            <Translations text={"Logout"} />
          </Buttons>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default LogoutDialog;
