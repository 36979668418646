function LogoutIcon({ strokes }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 39.514 38.183">
      <g id="Logout" transform="translate(-1.722 -0.75)">
        <path id="Path_862" data-name="Path 862" d="M25.4,11.371V4.856A2.607,2.607,0,0,0,22.8,2.25H5.856A2.607,2.607,0,0,0,3.25,4.856V34.827a2.607,2.607,0,0,0,2.606,2.606H22.8A2.607,2.607,0,0,0,25.4,34.827V28.311" fill="none" stroke="#1c355e" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
        <path id="Path_863" data-name="Path 863" d="M14.5,6.25l7.167,7.167L14.5,20.584" transform="translate(18.069 6.425)" fill="none" stroke="#1c355e" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
        <line id="Line_22" data-name="Line 22" x1="15.637" transform="translate(24.099 19.841)" fill="none" stroke="#1c355e" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
        <path id="Path_864" data-name="Path 864" d="M3.459,2.648l8.655,5.366a2.606,2.606,0,0,1,1.233,2.215V28.178a2.61,2.61,0,0,1-1.233,2.215L3.456,35.762" transform="translate(0.331 0.639)" fill="none" stroke="#1c355e" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
      </g>
    </svg>
  );
}
export default LogoutIcon;
