// ** Next Imports
import { NavLink, useLocation, useSearchParams } from "react-router-dom";

// ** MUI Imports
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled, useTheme } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";

// ** Configs Import
import themeConfig from "../../../../../configs/themeConfig";

// ** Custom Components Imports
import UserIcon from "../../../../../layouts/components/UserIcon";
import Translations from "../../../../../layouts/components/Translations";

// ** Utils
import { handleURLQueries } from "../../../../layouts/utils";
import { hexToRGBA } from "../../../../utils/hex-to-rgba";

// ** Styled Components
const MenuNavLink = styled(ListItemButton)(({ theme }) => ({
  width: "100%",
  borderRadius: 14,
  // borderTopRightRadius: 100,
  // borderBottomRightRadius: 100,
  paddingLeft: "22px !important",
  // paddingRight: "22px !important",
  color: theme.palette.secondary.default,
  transition: "padding-left .25s ease-in-out",
  fontWeight: 600,
  "&.active": {
    "&, &:hover": {
      // boxShadow: theme.shadows[3],
      // backgroundColor: hexToRGBA(theme.palette.primary.main, 0.25),
    },
    "& .MuiTypography-root, & .MuiListItemIcon-root": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));

const MenuItemTextMetaWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  transition: "opacity .25s ease-in-out",
  ...(themeConfig.menuTextTruncate && { overflow: "hidden" }),
});

const VerticalNavLink = ({
  item,
  parent,
  navHover,
  settings,
  navVisible,
  isSubToSub,
  collapsedNavWidth,
  toggleNavVisibility,
  navigationBorderWidth,
}) => {
  // ** Hooks
  const theme = useTheme();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // ** Vars
  const { skin, navCollapsed } = settings;
  const IconTag = parent && !item.icon ? themeConfig.navSubItemIcon : item.icon;

  const conditionalBgColor = () => {
    if (skin === "semi-dark" && theme.palette.mode === "light") {
      return {
        color: `rgba(${theme.palette.customColors.dark}, 0.87)`,
        "&:hover": {
          backgroundColor: `rgba(${theme.palette.customColors.dark}, 0.04)`,
        },
      };
    } else if (skin === "semi-dark" && theme.palette.mode === "dark") {
      return {
        color: `rgba(${theme.palette.customColors.light}, 0.87)`,
        "&:hover": {
          backgroundColor: `rgba(${theme.palette.customColors.light}, 0.04)`,
        },
      };
    } else return {};
  };

  const isNavLinkActive = () => {
    if (
      location.pathname === item.path ||
      handleURLQueries({ ...location, query: searchParams }, item.path)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ListItem
      disablePadding
      className="nav-link"
      disabled={item.disabled || false}
      sx={{ mt: 1.5, px: "0 !important" }}
    >
      {/* <Link to={item.path === undefined ? '/' : `${item.path}`}> */}
      <MenuNavLink
        to={item.path === undefined ? "/" : `${item.path}`}
        component={NavLink}
        className={isNavLinkActive() ? "active" : ""}
        {...(item.openInNewTab ? { target: "_blank" } : null)}
        onClick={(e) => {
          if (item.path === undefined) {
            e.preventDefault();
            e.stopPropagation();
          }
          if (navVisible) {
            toggleNavVisibility();
          }
        }}
        sx={{
          py: 1.5,
          px: 4,
          // py: 2.25,
          // ...conditionalBgColor(),
          // ...(item.disabled
          //   ? { pointerEvents: "none" }
          //   : { cursor: "pointer" }),
          // pl:
          //   navCollapsed && !navHover
          //     ? (collapsedNavWidth - navigationBorderWidth - 24) / 8
          //     : 5.5,
          // pr:
          //   navCollapsed && !navHover
          //     ? ((collapsedNavWidth - navigationBorderWidth - 24) / 2 - 5) / 4
          //     : 3.5,
        }}
      >
        {isSubToSub ? null : (
          <ListItemIcon
            sx={{
              minWidth: "unset",
              color: "text.primary",
              transition: "margin .25s ease-in-out",
              ...(navCollapsed && !navHover ? { mr: 0 } : { mr: 2.5 }),
              ...(parent ? { ml: 1.25, mr: 3.75 } : {}), // This line should be after (navCollapsed && !navHover) condition for proper styling
            }}
          >
            <UserIcon
              icon={IconTag}
              componentType="vertical-menu"
              iconProps={{
                sx: {
                  fontSize: "0.875rem",
                  ...(!parent ? { fontSize: "1.5rem" } : {}),
                  ...(parent && item.icon ? { fontSize: "0.875rem" } : {}),
                },
              }}
            />
          </ListItemIcon>
        )}

        <MenuItemTextMetaWrapper
          sx={{
            // ...(isSubToSub ? { ml: 9 } : {}),
            ...(navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 }),
          }}
        >
          <Typography
            sx={{
              fontWeight: "inherit",
            }}
            {...((themeConfig.menuTextTruncate ||
              (!themeConfig.menuTextTruncate && navCollapsed && !navHover)) && {
              noWrap: true,
            })}
          >
            <Translations text={item.title} />
          </Typography>
          {item.badgeContent ? (
            <Chip
              label={item.badgeContent}
              color={item.badgeColor || "primary"}
              sx={{
                ml: 1.25,
                height: 20,
                fontWeight: 500,
                "& .MuiChip-label": { px: 1.5, textTransform: "capitalize" },
              }}
            />
          ) : null}
        </MenuItemTextMetaWrapper>
      </MenuNavLink>
      {/* </Link> */}
    </ListItem>
  );
};

export default VerticalNavLink;
