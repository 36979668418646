import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { store } from "./store";
import { Provider } from "react-redux";
// import axios from 'axios'

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

//config add
import "../src/configs/i18n";

// ** Component Imports
import ThemeComponent from "./@core/theme/ThemeComponent";

// ** Global css styles
import "./assets/styles/index.scss";

// ** Contextes
import { AuthProvider } from "./context/AuthContext";
import { MiscProvider } from "./context/MiscContext";
import {
  SettingsConsumer,
  SettingsProvider,
} from "./@core/context/settingsContext";

// Country DropDown Css
import "react-phone-input-2/lib/style.css";
// axios.defaults.baseURL = 'http://dactest.ap-south-1.elasticbeanstalk.com/api/user/'

import "react-perfect-scrollbar/dist/css/styles.css";
import theme from "./components/customTheme/theme";

// REACT IMAGE CROP CSS
import "react-image-crop/dist/ReactCrop.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <MiscProvider>
          <SettingsProvider>
            <SettingsConsumer>
              {({ settings }) => {
                return (
                  <ThemeComponent settings={settings}>
                    <AuthProvider>
                      <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
                        <App />
                      </SnackbarProvider>
                    </AuthProvider>
                  </ThemeComponent>
                );
              }}
            </SettingsConsumer>
          </SettingsProvider>
        </MiscProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
