function SubscriptionIcon({ strokes }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40">
      <g id="subscription" transform="translate(-1.977 -1.933)">
        <path id="Path_827" data-name="Path 827" d="M35.977,13.933h-28a6.007,6.007,0,0,0-6,6v16a6.007,6.007,0,0,0,6,6h28a6.007,6.007,0,0,0,6-6v-16a6.007,6.007,0,0,0-6-6Zm2,22a2,2,0,0,1-2,2h-28a2,2,0,0,1-2-2v-16a2,2,0,0,1,2-2h28a2,2,0,0,1,2,2Zm-10-8a2,2,0,0,1-2,2h-2v2a2,2,0,0,1-4,0v-2h-2a2,2,0,1,1,0-4h2v-2a2,2,0,1,1,4,0v2h2A2,2,0,0,1,27.977,27.933Zm-20-18a2,2,0,0,1,2-2h24a2,2,0,1,1,0,4h-24A2,2,0,0,1,7.977,9.933Zm4-6a2,2,0,0,1,2-2h16a2,2,0,1,1,0,4h-16A2,2,0,0,1,11.977,3.933Z" transform="translate(0 0)" fill="#1c355e" />
      </g>
    </svg>
  );
}
export default SubscriptionIcon;
