// ** React Imports
import { useEffect, useCallback, useRef, useState } from "react";

// ** Next Imports
import { Link, useNavigate } from "react-router-dom";

// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import MuiDialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import InputAdornment from "@mui/material/InputAdornment";
import MuiAutocomplete from "@mui/material/Autocomplete";

// ** Icons Imports
import Close from "@mui/icons-material/Close";
import Magnify from "@mui/icons-material/Search";
import SubdirectoryArrowLeft from "@mui/icons-material/EditNoteRounded";
import { Search } from "@mui/icons-material";

// ** API Icon Import with object
import { axiosInstance } from "../../network/config/adapter";
import { ApiEndPoints } from "../../network/endpoints";
import { Avatar, Badge, InputBase } from "@mui/material";
import useCustomTranslation from "../../@core/hooks/useCustomeTranslation";
import SidebarArrow from "../../assets/images/sidebarArrow.svg";

// ** Styled Autocomplete component
const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  "& fieldset": {
    border: 0,
  },
  "& + .MuiAutocomplete-popper": {
    borderTop: `1px solid ${theme.palette.divider}`,
    "& .MuiAutocomplete-listbox": {
      paddingTop: 0,
      height: "100%",
      maxHeight: "inherit",
      "& .MuiListSubheader-root": {
        top: 0,
        fontWeight: 400,
        lineHeight: "15px",
        fontSize: "0.75rem",
        letterSpacing: "1px",
        color: theme.palette.text.disabled,
        padding: theme.spacing(3.75, 6, 0.75),
      },
    },
    "& .MuiAutocomplete-paper": {
      border: 0,
      height: "100%",
      borderRadius: 0,
      boxShadow: "none",
    },
    "& .MuiListItem-root.suggestion": {
      padding: 0,
      "& .MuiListItemSecondaryAction-root": {
        display: "flex",
      },
      "&.Mui-focused.Mui-focusVisible, &:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "& .MuiListItemButton-root: hover": {
        backgroundColor: "transparent",
      },
      "&:not(:hover)": {
        "& .MuiListItemSecondaryAction-root": {
          display: "none",
        },
        "&.Mui-focused, &.Mui-focused.Mui-focusVisible:not(:hover)": {
          "& .MuiListItemSecondaryAction-root": {
            display: "flex",
          },
        },
        [theme.breakpoints.down("sm")]: {
          "&.Mui-focused:not(.Mui-focusVisible) .MuiListItemSecondaryAction-root":
          {
            display: "none",
          },
        },
      },
    },
    "& .MuiAutocomplete-noOptions": {
      display: "grid",
      minHeight: "100%",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      padding: theme.spacing(10),
    },
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    // borderRadius: "32px !important",
    border: "none",
    backgroundColor: "#D9DFE8",
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
      // backgroundColor: "#D9DFE8",
    },
  },
}));

// ** Styled Dialog component
const Dialog = styled(MuiDialog)({
  "& .MuiBackdrop-root": {
    backdropFilter: "blur(4px)",
  },
  "& .MuiDialog-paper": {
    overflow: "hidden",
    "&:not(.MuiDialog-paperFullScreen)": {
      height: "100%",
      maxHeight: 550,
    },
  },
});

const SearchContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  background: "#F1F5F9",
  border: "1px solid #D9DFE8",
  borderRadius: "200px",
  padding: "3px 10px",
});

const SearchIcon = styled(Search)({
  marginRight: 8,
  color: "#303A4B",
});

const SearchInput = styled(InputBase)({
  flex: 1,
  "&: hover MuiInputBase-input": {
    cursor: "pointer",
  },
});

const AvatarStyle = {
  display: "flex",
  justifyContent: "flex-end",
  borderRadius: "200px",
  border: "1px solid #D9DFE8",
  backgroundColor: "#F1F5F9",
};

const NoResult = ({ value, setOpenDialog }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography variant="h6" sx={{ mb: 11.5, wordWrap: "break-word" }}>
        No results for{" "}
        <Typography
          variant="h6"
          component="span"
          sx={{ wordWrap: "break-word" }}
        >
          {`"${value}"`}
        </Typography>
      </Typography>
    </Box>
  );
};

const AutocompleteComponent = ({ hidden, settings }) => {
  // ** States
  const [isMounted, setIsMounted] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [options, setOptions] = useState([]);

  // ** Hooks & Vars
  const theme = useTheme();
  const navigate = useNavigate();
  const translations = useCustomTranslation();
  const { layout } = settings;
  const wrapper = useRef(null);
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchData = (searchValue) => {
    axiosInstance
      .get(ApiEndPoints.DASHBOARD.get_holdings_list, {
        params: { search: searchValue },
      })
      .then((response) => response.data)
      .then((response) => {
        setOptions(
          response.stockHoldings.map((event) => ({
            ...event,
            url: `dashboard/${event._id}`,
          }))
        );
      })
      .catch(() => {
        setOptions([]);
      });
  };

  useEffect(() => {
    fetchData("");
  }, []);

  // Get all data using API
  useEffect(() => {
    fetchData(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  // Handle click event on a list item in search result
  const handleOptionClick = (obj) => {
    setOpenDialog(false);
    setSearchValue("");
    if (obj?.url) {
      setTimeout(() => {
        navigate(`dashboard/main/${obj._id}`);
      }, 1000);
    }
  };

  // Handle ESC & shortcut keys keydown events
  const handleKeydown = useCallback(
    (event) => {
      // ** Shortcut keys to open searchbox (Ctrl + /)
      if (!openDialog && event.ctrlKey && event.which === 191) {
        setOpenDialog(true);
      }
    },
    [openDialog]
  );

  // Handle shortcut keys keyup events
  const handleKeyUp = useCallback(
    (event) => {
      // ** ESC key to close searchbox
      if (openDialog && event.keyCode === 27) {
        setOpenDialog(false);
      }
    },
    [openDialog]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp, handleKeydown]);

  if (!isMounted) {
    return null;
  } else {
    return (
      <Box
        ref={wrapper}
        onClick={() => !openDialog && setOpenDialog(true)}
        sx={{ display: "flex", cursor: "pointer", alignItems: "center" }}
      >
        <Box
          display={{
            xs: "none",
            sm: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          }}
        >
          <SearchContainer>
            <SearchIcon />
            <SearchInput
              placeholder={translations.translate("Search here...")}
              readOnly={true}
            />
          </SearchContainer>
        </Box>
        <Box
          display={{
            xs: "flex",
            sm: "flex",
            md: "none",
            lg: "none",
            xl: "none",
          }}
        >
          <Badge
            color="secondary"
            badgeContent={0}
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <Avatar alt="Remy Sharp" sx={{ ...AvatarStyle }}>
              <SearchIcon />
            </Avatar>
          </Badge>
        </Box>
        <Dialog
          fullWidth
          open={openDialog}
          fullScreen={fullScreenDialog}
          onClose={() => setOpenDialog(false)}
          PaperProps={{
            sx: {
              borderRadius: "30px"
            }
          }}
        >
          <Box sx={{ top: 0, width: "100%", position: "sticky" }}>
            <Autocomplete
              autoHighlight
              disablePortal
              options={options}
              id="appBar-search"
              isOptionEqualToValue={() => true}
              onInputChange={(event, value) => setSearchValue(value)}
              onChange={(event, obj) => handleOptionClick(obj)}
              noOptionsText={
                <NoResult value={searchValue} setOpenDialog={setOpenDialog} />
              }
              placeholder="Search here..."
              getOptionLabel={(option) => option.contract_name}
              // groupBy={option => (searchValue.length ? categoryTitle[option.category] : '')}
              sx={{
                "& + .MuiAutocomplete-popper": {
                  ...(searchValue.length && {
                    overflow: "auto",
                    maxHeight: "calc(100vh - 69px)",
                    height: fullScreenDialog ? "calc(100vh - 69px)" : 481,
                  }),
                },
              }}
              renderInput={(params) => {
                return (
                  <CustomTextField
                    {...params}
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    inputRef={(input) => {
                      if (input) {
                        if (openDialog) {
                          input.focus();
                        } else {
                          input.blur();
                        }
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      sx: { p: `${theme.spacing(3.75, 6)} !important` },
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ color: "text.primary" }}
                        >
                          <Magnify />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() => setOpenDialog(false)}
                          sx={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "center",
                          }}
                        >
                          <IconButton size="small" sx={{ p: 1 }}>
                            <Close fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                );
              }}
              renderOption={(props, option) => {
                return (
                  <ListItem
                    {...props}
                    key={option._id}
                    className={`suggestion ${props.className}`}
                    onClick={() => handleOptionClick(option)}
                    secondaryAction={
                      <Avatar src={SidebarArrow} alt="SidebarArrow" sx={{ cursor: "pointer", color: "text.disabled", height: "25px", width: "25px" }} />
                      // <SubdirectoryArrowLeft
                      //   fontSize="small"
                      //   sx={{ cursor: "pointer", color: "text.disabled" }}
                      // />
                    }
                  >
                    <ListItemButton
                      sx={{
                        py: 2.5,
                        px: ` ${theme.spacing(6)} !important`,
                        display: "flex",
                        gap: "10px",
                        borderBottom: "1px solid #D9DFE8"
                      }}
                    >
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{ color: "text.primary" }}
                        >
                          {option.contract_name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "text.disabled", display: "none" }}
                        >
                          {option.contract_name}
                        </Typography>
                      </Box>
                    </ListItemButton>
                  </ListItem>
                );
              }}
            />
          </Box>
        </Dialog>
      </Box>
    );
  }
};

export default AutocompleteComponent;
