// ** MUI Imports
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// ** Icons Imports
import MenuIcon from "@mui/icons-material/Menu";

// ** Components
import UserDropdown from "../../../@core/layouts/components/shared-components/UserDropdown";
import Notification from "../../../@core/layouts/components/shared-components/notification";
import SearchBar from "../../../@core/layouts/components/shared-components/searchBar";
import { Avatar, Badge, Button, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
// import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown'
import Seller from "../../../assets/images/seller.png";
import AutoCompleteComponent from "../Autocomplete";
import LanguageDropdown from "../../../@core/layouts/components/shared-components/LanguageDropdown";
import Translations from "../Translations";
import Thanawi_logo from "../../../assets/images/logo_Thanawi.svg";


const AvatarStyle = {
  borderRadius: "200px",
  border: "1px solid #D9DFE8",
  backgroundColor: "#F1F5F9",
};

const BecomeASellerButton = styled(Button)(({ theme }) => ({
  // height: "40px",
  border: "1px solid #007168",
  fontFamily: "Cairo, sans-serif",
  fontSize: "20px",
  fontWeight: "bold",
  lineHeight: "25px",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  borderRadius: "20px",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
  "&:hover": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}));
const ThanawiLogoStyle = styled(Box)(({ theme }) => ({
  display: "block",
  [theme.breakpoints.down("1200")]: {
    display: "none",
  },

}));

const AppBarContent = (props) => {
  const sideBarMediaQRY = useMediaQuery('(max-height:1200px)')

  console.log("sideBarMediaQRY", sideBarMediaQRY);


  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <ThanawiLogoStyle>
        <img src={Thanawi_logo} alt="Thanawi_logo" />
      </ThanawiLogoStyle>
      <Box
        className="actions-left"
        sx={{
          mr: 2,
          display: sideBarMediaQRY ? "flex" : "none",
          alignItems: "center",
        }}
      >
        {hidden ? (
          <IconButton
            color="inherit"
            sx={{ ml: -2.75 }}
            onClick={toggleNavVisibility}
          >
            <MenuIcon />
          </IconButton>
        ) : null}
      </Box>

      <Box
        className="actions-right"
        sx={{ display: "flex", alignItems: "center", gap: "10px" }}
      >
        {/* <NotificationDropdown settings={settings} /> */}
        <Link to={"/settings/main/buyer"} style={{ textDecoration: "none" }}>
          <BecomeASellerButton size="large">
            <Translations text={"Offer your Shares"} />
          </BecomeASellerButton>

          <Badge
            color="secondary"
            badgeContent={0}
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <Link to={"/settings/main/buyer"} style={{ textDecoration: "none" }}>
              <Avatar alt="Remy Sharp" sx={{ ...AvatarStyle }}>
                <img
                  src={Seller}
                  alt="BellIcon"
                  style={{ height: "30px", width: "30px" }}
                ></img>
              </Avatar>
            </Link>
          </Badge>
        </Link>
        <AutoCompleteComponent hidden={hidden} settings={settings} />
        <Notification />
        <LanguageDropdown settings={settings} saveSettings={saveSettings} />
        <UserDropdown settings={settings} />
      </Box>
    </Box >
  );
};

export default AppBarContent;
